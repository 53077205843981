import React from 'react';

const Header = () => (
  <div>
    <header className="text-center mb-16">
        <h2 className="text-4xl font-bold mb-2 text-white glitch">3D Video Reconstruction</h2>
        <p className="text-lg text-gray-500">Transforming reality into digital dimensions</p>
    </header>

    <div className="max-w-3xl mx-auto mb-16 text-center">
        <p className="text-sm leading-relaxed">
            We create 3D video reconstructions using advanced computer vision. Standard footage becomes detailed three-dimensional scenes, unlocking new perspectives for sports analysis, entertainment, and research.
        </p>
    </div>
  </div>
);

export default Header;
