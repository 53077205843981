import React from 'react';
import Header from './Header';
import ThumbnailGrid from './ThumbnailGrid';
import Footer from './Footer';

import 'tailwindcss/tailwind.css';
import './App.css';

const App = () => (
  <div className="bg-dark-bg text-gray-300 font-mono">
    <nav className="py-4 border-b border-gray-800">
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-bold text-accent glitch">Impossible Motion</h1>
      </div>
    </nav>

    <div className="container mx-auto px-4 py-16">
      <Header />
      <ThumbnailGrid />

      <div className="mt-20 text-center">
        <h3 className="text-xl font-semibold mb-4">Interested? Reach out.</h3>
        <a href="mailto:hello@impossiblemotion.io" className="inline-block bg-transparent border border-accent text-accent hover:bg-accent hover:text-black font-semibold py-2 px-6 transition-colors duration-300">
          Contact
        </a>
      </div>

    </div>

    <Footer />
  </div>
);

export default App;
