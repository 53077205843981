import React from 'react';

const Footer = () => (
  <footer className="py-8 mt-16 border-t border-gray-800">
    <div className="container mx-auto px-4 text-center">
      <p className="text-xs">&copy; 2024 Impossible Motion. All rights reserved.</p>
      <p className="mt-2 text-xs">
        <a href="mailto:hello@impossiblemotion.io" className="text-accent hover:underline">hello@impossiblemotion.io</a>
      </p>
    </div>
  </footer>
);

export default Footer;
